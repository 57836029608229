import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import VuexReset from '@ianwalter/vuex-reset'
import Axios from 'axios'


Vue.use(Vuex)


const offerStore = {
    namespaced: true,
    state: {
        offerData: {
            Offer: {},
            ViewModel: {
                subscriptions: [],
                titleDetails: []
            },
            Editable: true, //(ViewModel.existingSubscriptions !== undefined),
            EditingExistingOffer: false,
            DefaultSavings: '30',
            Percentage: Array.from(new Array(21), (x, i) => (i * 5)),
            DefaultDuration: null,
            IsAllSelected: false,
            Selected: [],
            showTier: null,
            addTier: false,
            template: 1000,
            index: 0,
            IsPublicOffer: false,
            currentPosition: null,
            ImageFile: null,
            defaultColor: '#30c3f9',
            bundleTier: null,
            BundlePercentageSaving: 0,
            BundleSaving: 0,
            isBundle: false,
            disableLandingPage: false,
            ShowIPadCover: false,
            bundlePercentage: [],
            urlAvailable: null,
            UTMDialogue: false,
            validOfferSlugUrl: null,
            validSlugMessage: '',
            Price: null,
            IsLoading: true,
            isLoading: false,
            remainingDescriptionCount: 180,
            showConfirmation: false,
            showLastestIssue: true,
            showMarketingOption: true,
            showBundleConfirmation: false,
            cachedData: [],
            TotalPercentage: 0,
            LatestCover: null,
            totalBundleOfferPrice: 0,
            duplicateOffer: false,
            disableBundleConfirmation: true,
            BundleIssuesOnly: false,
            bundleStep: 0,
            TrackingHeader: 'Add Tracking',
            today: new Date(),
            date: {
                startDate: new Date(),
                endDate: new Date()
            },
            prefixSlugUrl: 'http://pocketmags.com/ [......] / [......]',
            masks: {
                inputDateTime: 'DD-MM-YYYY HH:mm',
            },
            dropFiles: [],
            wizardCurrentStep: '',
            resetStep2: false,
        },
    },
    mutations: {
        basic(state, payload) {
            state.offerData[payload.key] = payload.value
        },
        setAllOfferData(state, payload){
            state.offerData = payload
        }
    },
    actions: {
        setOfferData({ commit }, payload) {
            commit('setAllOfferData', payload)
        },
        async createNewOffer({ state, commit }, clientId) {        
            await Axios.get(`client/${clientId}/create-new-offer`).then(function (responseOffer) {

                var ViewModel = responseOffer.data.titleSubscriptions || responseOffer.data.subscriptions

                var Offer = responseOffer.data.offer
                Offer.offerTypeId = 0;
                Offer.visibility = true;

                var offerObject = { key: 'Offer', value: Offer }
                var viewModelObject = { key: 'ViewModel', value: ViewModel }

                commit('basic', viewModelObject)
                commit('basic', offerObject)

            })
        },
    },
    getters: {
        getOfferData(state) {
            return state.offerData
        }
    },
};

export default new Vuex.Store({
    plugins: [
        createPersistedState({
            //storage: window.sessionStorage,
            //only persist these fields (related to login) a refresh should reload all other fields
            paths: ['user',
                'clientTitlesBase', 'defaultTitle', 'countries', 'countriesExpiry', 'pricingTiers', 'pricingTierExpiry',
            ]
        }),
        VuexReset()
    ],
    state: {
        user: null,

        /*User Client Specific settings*/
        clientTitlesBase: null,
        defaultTitle: null,
        titleSchedules: [],
        titleCollections: [],

        /*Generic settings*/
        pricingTierExpiry: null,
        pricingTiers: [],
        countriesExpiry: null,
        countries: [],
        TrialLengths:[],

        hasUnsavedChanges: false,
        mainWindowScrolled: false,
        hasBreadcrumbDescription: false,
        isAsideMobileExpanded: false,

        pageContent: {}, //array but using objet so can use strings
        faqContent: [], //array but using objet so can use strings

        endpointPromise: {}, //store the promise here, and check it to provent multiple loads

        pageLoading: false,

        showSupportDialog: false
    },
    mutations: {
        resetLogin: (state) => {

            //clean up other user specifc settings
            state.clientTitlesBase = null;

        },
        /* A fit-them-all commit */
        basic(state, payload) {
            state[payload.key] = payload.value
        },

        /* User */
        user(state, payload) {
            state.user = payload;
        },
        updateUser: (state, payload) => {
            state.user.firstName = payload.firstName
            state.user.lastName = payload.lastName
            state.user.email = payload.email
            state.user.phone = payload.phone
            state.user.currencyId = payload.currencyId          
        },
        /*Client*/
        storeClientTitlesBase(state, payload) {
            state.clientTitlesBase = payload

        },

        refreshDefaultTitle(state) {
            //if the default title doesn't match the title list then reset it to first in the title list
            if (state.defaultTitle === null || !state.clientTitlesBase.some(function (p) { return p.id === state.defaultTitle.id })) {
                //not set to one of the titles, so update it to first one (maybe switch logins caused this, or access to title removed?)
                var defaultTitle = state.clientTitlesBase.find(o => o.isLive === true);
                if (defaultTitle === undefined)
                    defaultTitle = state.clientTitlesBase.find(o => o.isLive === false);
                state.defaultTitle = defaultTitle;

            }
        },


        setPageLoading(state, isLoading) {
            state.pageLoading = isLoading;
        },
        setSupportDialog(state, showDialog) {
            state.showSupportDialog = showDialog;
        },

        setSelectedTitle(state, payload) {
            state.defaultTitle = payload;
        },

        storePricingTiers(state, payload) {
            state.pricingTiers = payload;

            var expiryDate = new Date();
            expiryDate.setDate(expiryDate.getDate() + 2);
            state.pricingTierExpiry = expiryDate;
        },
        storeCountries(state, payload) {
            state.countries = payload;

            var expiryDate = new Date();
            expiryDate.setDate(expiryDate.getDate() + 2);
            state.countriesExpiry = expiryDate;
        },


        storeTitleSchedules(state, payload) {
            Vue.set(state.titleSchedules, payload.titleId, payload.data);
        },

        storeTitleCollections(state, payload) {
            Vue.set(state.titleCollections, payload.titleId, payload.data);
        },
        

        storePageContents(state, payload) {
            Vue.set(state.pageContent, payload.key, payload.data);
        },

        storePromise(state, payload) {
            Vue.set(state.endpointPromise, payload.key, payload.data);
        },

        storeFaqContents(state, payload) {
            state.faqContent = payload;
        },
        storeHasUnsavedChange(state, payload) {
            state.hasUnsavedChanges = payload;
        },

        storeMainWindowScrolled(state, payload) {
            state.mainWindowScrolled = payload;
        },

        storeHasBreadcrumbDescription(state, payload) {
            state.hasBreadcrumbDescription = payload;
        },

        storeTrialLengths(state, payload) {
            state.TrialLengths = payload
        },
        

        asideMobileStateToggle(state, payload = null) {
            var childEl = document.getElementsByClassName(['main-content'])
            var sidebar = document.getElementById('sidebar-menu')

            let isShow

            if (payload !== null) {
                isShow = payload
            } else {
                isShow = !state.isAsideMobileExpanded
            }

            if (isShow) {
                if (childEl.length > 0) {
                    childEl[0].parentElement.classList.add(['main-body-content-mobile'])
                    sidebar.classList.add(['sidebar-menu-mobile'])
                }

            }
            else {
                if (childEl.length > 0) {
                    childEl[0].parentElement.classList.remove(['main-body-content-mobile'])
                    sidebar.classList.remove(['sidebar-menu-mobile'])
                }

            }

            state.isAsideMobileExpanded = isShow
        },
    },
    actions: {
        async authenicate({ commit }, payload) {

            if (payload.options === undefined)
                payload.options = null;

            if (payload.form === undefined)
                payload.form = null;

            await Axios.post(payload.url, payload.form, payload.options).then(function (response) {
                commit('user', response.data)
                return null;

            });
        },

        async loadClientUserTitles({ state, commit }) {
            if (state.clientTitlesBase === null || state.clientTitlesBase.length === 0) {
                state.defaultTitle = null; //clear default as we reloading it

               var success = await Axios.get('titles-base')
                    .then(async response => {
                        commit('storeClientTitlesBase', response.data);

                        if (response.data.length === 0) {
                            return false;
                        } else {
                            commit('refreshDefaultTitle');
                            return true;
                        }
                    });
                return success
            } else {
                commit('refreshDefaultTitle');
                return state.clientTitlesBase;
            }
        },

        loadPricingTiers({ state, commit }) {
            if (state.pricingTiers === null || state.pricingTiers.length === 0 || state.pricingTierExpiry == null || state.pricingTierExpiry < new Date()) {
                Axios.get(`pricing-tiers`)
                    .then(function (response) {
                        commit('storePricingTiers', response.data);
                    })
            }
        },
        loadCountries({ state, commit }) {
            if (state.countries === null || state.countries.length === 0 || state.countriesExpiry == null || state.countriesExpiry < new Date()) {
                Axios.get(`countries`)
                    .then(function (response) {
                    commit('storeCountries', response.data)
                })
            }
        },

        async loadTitleSchedule({ state, dispatch }, titleId) {

            if (titleId in state.titleSchedules) {
                return state.titleSchedules[titleId];
            } else {
                return dispatch("loadTitleScheduleForce", titleId);
            }
        },

        async loadTitleScheduleForce({ state, commit }, titleId) {
            return Axios
                .get("title/" + titleId + "/issue-schedules?perPage=500")
                .then(function (response) {


                    commit('storeTitleSchedules', { data: response.data, titleId: titleId });
                    return state.titleSchedules[titleId];
                })
        },

        async loadTitleCollection({ state, dispatch }, titleId) {

            if (titleId in state.titleCollections) {
                return state.titleCollections[titleId];
            } else {
                return dispatch("loadTitleCollectionForce", titleId);
            }
        },

        async loadTitleCollectionForce({ state, commit }, titleId) {
            return Axios
                .get("title/" + titleId + "/collections?perPage=500")
                .then(function (response) {


                    commit('storeTitleCollections', { data: response.data, titleId: titleId });
                    return state.titleCollections[titleId];
                })
        },

        

        loadTrialLengths({ state, commit }) {
            if (state.TrialLengths === null || state.TrialLengths.length === 0) {
                Axios.get(`trial-lengths`).then(function (response) {
                        var TrialLengths = response.data.map(function (r) {
                            return {
                                Id: r.id,
                                Name: r.length,
                            }
                        });
                        commit('storeTrialLengths', TrialLengths)
                    })
            }
        },


        setPageHasChanges({ commit }, hasChange) {
            commit('storeHasUnsavedChange', hasChange);
        },

        setMainWindowScrolled({ commit }, scrolled) {
            commit('storeMainWindowScrolled', scrolled);
        },
        setHasBreadcrumbDescription({ commit }, hasDescription) {
            commit('storeHasBreadcrumbDescription', hasDescription);
        },

        async loadPageContent({ state, commit }, key) {

            var promiseKey = "LoadPageContent" + key;

            //load from cache
            if (state.pageContent[key] !== null && state.pageContent[key] !== undefined)
                return state.pageContent[key];

            //return existing promise, prevents multiple api calls
            if (state.endpointPromise[promiseKey] !== null && state.endpointPromise[promiseKey] !== undefined)
                return state.endpointPromise[promiseKey];

            //call endpoint
            let promise = Axios
                .get(`page-content?slugUrl=${key}`)
                .then(function (response) {
                    commit('storePageContents', { key: key, data: response.data });
                    commit('storePromise', { key: promiseKey, data: null }); //clear promise
                    return response.data;
                });

            commit('storePromise', { key: promiseKey, data: promise });
            return promise;
        },

        async refreshToken({ state, commit }, key) {

            var promiseKey = "RefreshTokenUser" + key.refreshToken;

            //return existing promise, prevents multiple api calls
            if (state.endpointPromise[promiseKey] !== null && state.endpointPromise[promiseKey] !== undefined)
                return state.endpointPromise[promiseKey];

            //call endpoint
            let promise = Axios.post('refresh-token', key)
                .then(function (response) {
                    commit('user', response.data); //set data
                    commit('storePromise', { key: promiseKey, data: null }); //clear promise
                    return response.data.accessToken;
                });

            commit('storePromise', { key: promiseKey, data: promise });
            return promise;
        },


        loadFaqContent({ state, commit }) {
            if (state.faqContent === null || state.faqContent.length === 0) {
                Axios.get(`faqs`)
                    .then(function (response) {
                        commit('storeFaqContents', response.data)
                    })
            }
        },



        //Auto load data on calling the action
        //could we timestamp in an array 
        //on load, check if we have data and if its in date, if not then call API

    },
    getters: {
    },
    modules: {
        offerStore: offerStore
    }
})