import store from '../store'
import router from '../router'

export default {
    async refreshAccessToken() {
        var refreshTokenRequest = {}

        var user = store.state.user;

        var res;
        if (user && user.refreshToken) {
            refreshTokenRequest.refreshToken = user.refreshToken;

            var path = router.app.$route.path;
            await store.dispatch('refreshToken', refreshTokenRequest).then(res => {
                return res
            }).catch(error => {
                window.sessionStorage.clear()
                router.push({ name: 'LoginView', query: { redirecturl: path } });
                return null;
            });

        } else {
            window.sessionStorage.clear()
            router.push({ name: 'LoginView', query: { redirecturl: path } });
            return null;
        }
    }
}