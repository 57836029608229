import { mapState } from 'vuex';
import Axios from 'axios';

export default {
    computed: {
        ...mapState(['pricingTiers', 'clientTitlesBase', 'hasUnsavedChanges']),
        localTimeZone() {
            return new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
        },
    },
    methods: {
        getPriceByUserCurrency(pricingTierId) {
            var tier = this.getPriceTierById(pricingTierId);

            if (tier["gbp"] === 0)
                return "FREE";

            switch (this.$store.state.user.currencyId) {
                case 3:
                    return tier["euro"] + " EUR";
                case 4:
                    return tier["gbp"] + " GBP"; //TODO returning ? causing encoding problem needs fixing,
                case 5:
                    return "$" + tier["usd"];
                case 6:
                    return "$" + tier["aud"];
                case 7:
                    return "$" + tier["cad"];
                default:
                    return tier["gbp"] + " GBP";
            }
        },
        getPriceTierById(pricingTierId) {
            //var rr = pricingTierId + 1; if
            return this.pricingTiers.filter(function (pt) { return pt.id === pricingTierId })[0];
        },
        isAdmin() {
            var role = this.$store.state.user.role

            return role === 'admin' ? true : false
        },
        customFormatDate(value, formatType) {
            if (value !== '-' && value !== null) {
                return this.$dayjs(value).format(formatType)
            }
        },

        isInFuture(value) {
            var checkDate = this.$dayjs(value);
            var now = this.$dayjs();
            return checkDate.isAfter(now);
        },
        formatDateTimeFutureHasTime(value) {
            return this.$dayjs(value).format(this.isInFuture(value) ? 'DD MMM YYYY HH:mm:ss' : 'DD MMM YYYY')
        },
        formatDate(value) {
            if (value !== '-' && value !== null) {
                return this.$dayjs(value).format('DD MMM YYYY')
            }
        },
        formatDateTime(value) {
            if (value !== '-' && value !== null) {
                return this.$dayjs(value).format('DD MMM YYYY - HH:mm')
            }
        },
        formatDateSQL(value) {
            return this.$dayjs(value).format('YYYY-MM-DD HH:mm:ss')
        },
        formatOnlyDate(value) {
            return this.$dayjs(value).format('DD MMM YYYY')
        },
        formatOnlyTime(value) {
            return this.$dayjs(value).format('HH:mm:ss')
        },
        formatFullTime(value) {
            return this.$dayjs(value).format('HH:mm')
        },
        formatFullTimeWithTz(value) {
            return this.formatFullTime(value) + ' ' + this.localTimeZone;
        },
        formatFullDate(value) {
            return this.$dayjs(value).format('dddd, MMMM D')
        },
        formatOnlyDateFromLocalToUK(value) {
            if (value === null)
                return "";
            return this.$dayjs(value).tz("Europe/London").format('DD MMM YYYY - HH:mm') 
        },
        addDaysFromDate(date, days) {
            var d = new Date(date)
            d.setDate(d.getDate() + days)

            return d
        },
        subtractDaysFromDate(date, days) {
            var d = new Date(date)
            d.setDate(d.getDate() - days)

            return d
        },
        getDateAsISOFormat(date) {
            //=2019-05-01
            var d = date
                .getDate()
                .toString()
                .padStart(2, "0");
            var m = (date.getMonth() + 1).toString().padStart(2, "0");
            var y = date.getFullYear();
            return `${y}-${m}-${d}`;
        },
        dateDiff(value1, value2) {
            const date1 = this.$dayjs(value1)
            const date2 = this.$dayjs(value2)
            var result = date1.diff(date2, 'day')
            return result;
        },
        liveInDaysText(value1) {
            var value2 = new Date();
            var result = this.dateDiff(value1, value2);

            if (result === 0)
                return "Live later today";
            else if (result === 1)
                return "Live tomorrow";
            else
                return "Live in " + result + " days";
        },
        trimLength(string, length) {
            return string.length > length ? string.substring(0, length - 3) + "..." : string.substring(0, length);
        },
        convertModelToFormData(model) {
            let formData = new FormData();

            for (var key in model) {
                if (model[key] !== null) {
                    if (model[key] instanceof Date) {
                        formData.append(key, model[key].toJSON());
                    }
                    else if (model[key] instanceof Array) {
                        for (var i = 0; i < model[key].length; i++) {
                            formData.append(key + "[" + i + "]", model[key][i]);
                        }
                    }
                    else {
                        formData.append(key, model[key]);
                    }
                }
            }
            return formData;
        },
        convertBytesToFormattedSize(numberOfBytes, decimalPlaces = 2) {
            if (0 === numberOfBytes)
                return "0 Bytes";
            decimalPlaces = 0 > decimalPlaces ? 0 : decimalPlaces;
            var value = Math.floor(Math.log(numberOfBytes) / Math.log(1024));

            return parseFloat((numberOfBytes / Math.pow(1024, value)).toFixed(decimalPlaces)) + " " + ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][value]

        },
        GetValidationError(field) {
            if (this.modelState === null
                || this.modelState.errors === null
                || this.modelState.errors[field] == null
                || this.modelState.errors[field] == undefined)
                return null;
            return this.modelState.errors[field][0];
        },
        HasValidationError(field) {
            return this.GetValidationError(field) !== null;
        },
        getXCagoStatus(sendForMobileEdition, statusId) {
            switch (statusId) {

                case 3: //Sent to processing team
                case 8: //Failed to process
                    return "Sent for processing";
                case 4: //Processed and received
                    return "Successfully processed";
                default:  //1	Unknown, 2	Awaiting Process, 5	Resend for processing, 6	Special Awaiting Status
                    return sendForMobileEdition ? "File will be sent for mobile processing (1-5 days)" : "Not enabled";
            }
        },
        UploadLayoutLink(guid) {
            return process.env.VUE_APP_LEGACYSITE + 'IssueLayout/' + guid;
        },
        MultimediaLink(guid) {
            return process.env.VUE_APP_LEGACYSITE + 'Issue/' + guid;
        },
        MobileEditionLink(guid) {
            return process.env.VUE_APP_LEGACYSITE + 'Epub/index/' + guid;
        },
        titlePlatformFormIcon(id) {
            switch (id) {
                case 1:
                    return 'mdi mdi-apple'
                case 2:
                    return 'mdi mdi-android-debug-bridge'
                case 3:
                    return 'mdi mdi-lock'
                case 1002:
                    return 'mdi mdi-web'
                case 7:
                case 29:
                    return 'mdi mdi-amazon'
                case 5:
                    return '../../pocketmags16.png'
                case 34:
                    return '../../jmag16.png'
            }
        },
        forgotUsername() {
            var that = this
            this.$buefy.dialog.prompt({
                message: `Please check your junk email if you do not receive a username reminder. Please contact <a href="mailto:support@jellyfishconnect.com">Support</a> if you have any problems.`,
                inputAttrs: {
                    placeholder: 'Enter Your Email'
                },
                trapFocus: true,
                onConfirm: (value) => {
                    Axios.post(`client-user/${value}/forgot-username`).then(function () {
                        that.$buefy.toast.open({
                            message: 'Username reminder sent',
                            type: 'is-success'
                        })
                    }).catch((error) => {
                        that.$buefy.snackbar.open(error.message)
                    })
                }
            })
        },
        forgotPassword() {
            var that = this
            this.$buefy.dialog.prompt({
                message: `Please check your junk email if you do not receive a link to reset your password. Please contact <a href="mailto:support@jellyfishconnect.com">Support</a> if you have any problems. <a onclick="forgotUsername()">Forgot Username?</a>`,
                inputAttrs: {
                    placeholder: 'Enter Your Username'
                },
                trapFocus: true,
                onConfirm: (value) => {
                    Axios.post(`client-user/${value}/forgot-password`).then(function () {
                        that.$buefy.toast.open({
                            message: 'Reset Password Email Sent',
                            type: 'is-success'
                        })
                    }).catch((error) => {
                        that.$buefy.snackbar.open(error.message)
                    })
                }
            })
        },
        hasTitleAccessOrRedirect(titleId) {
            if (this.hasTitleAccess(titleId)) {
                return true;
            } else {
                this.$router.push({ name: 'NoTitleAccess' })
                return false;
            }
        },
        hasTitleAccess(titleId) {
            return this.clientTitlesBase.filter(function (t) { return t.id == titleId }).length > 0;
        },
        getTitleFromId(titleId) {
            return this.clientTitlesBase.filter(function (t) { return t.id === titleId })[0];
        },
        getTitlesExceptCurrent(titleId) {
            return this.clientTitlesBase.filter(function (value) {
                return value.id !== titleId;
            });
        },
        async isAppPresentForTitle(titleId) {
            var success = await Axios.get(`title/${titleId}/app/is-app-live`).then(async response => {
                return response.data
            }).catch((error) => {
                if (error.response.status === 404) {
                    return false
                }
            })

            return success;
        },
        async loadExistingOffer(offerId) {

            var existingOffer = await Axios.get(`client/${this.$store.state.user.clientId}/offer/${offerId}/existing-offer`).then(async response => {
                return response.data
            }).catch(() => {
            })

            return existingOffer
        },
        navBarDisplay(display) {
            if (display) {
                document.getElementById('app').classList.add(['has-navbar-fixed-top'])
                document.body.classList.add(['has-navbar-fixed-top'])
            } else {
                document.getElementById('app').classList.remove(['has-navbar-fixed-top'])
                document.body.classList.remove(['has-navbar-fixed-top'])
            }
        },
        //Functions related to filtering pages and updating the URL to include the querystring, and getting the 
        getUrlByEndpointAndSearchObject(endpoint, search) {
            return endpoint + this.getQueryStringFromSearch(search);
        },
        getQueryStringFromSearch(search) {
            var qs = "?pageNumber=" + search.pageNumber
                + "&perPage=" + search.perPage;

            if (search.hasOwnProperty('sortField')) {
                qs += "&sortField=" + search.sortField
                    + "&sortOrder=" + search.sortOrder
            }

            //build api query from url
            if (search.dateRange) {
                qs += "&startDate=" + this.getDateAsISOFormat(search.dateRange[0]);
                qs += "&endDate=" + this.getDateAsISOFormat(search.dateRange[1]);
            }

            for (var key in search.filter) {
                if (search.filter[key] !== null && search.filter[key] !== "")
                    qs += "&" + key + "=" + search.filter[key];
            }

            return qs;
        },
        setDataFromQuery(search) {
            search.pageNumber = this.$route.query.pageNumber ? parseInt(this.$route.query.pageNumber) : search.pageNumber;
            search.perPage = this.$route.query.perPage ? parseInt(this.$route.query.perPage) : search.perPage; //use default if not set

            //if we have dates in url, then set them up
            if (this.$route.query.startDate)
                search.dateRange[0] = new Date(this.$route.query.startDate);
            if (this.$route.query.endDate)
                search.dateRange[1] = new Date(this.$route.query.endDate);

            for (var key in search.filter) {
                search.filter[key] = this.$route.query[key] || null; //this was current what it should be
            }
        },
        loadFromAddressBarPagination() {
            var query = this.$route.query;
            if (this.filter !== null) {
                if (query["pageNumber"])
                    this.filter.pageNumber = parseInt(query["pageNumber"]);
                if (query["perPage"])
                    this.filter.pageNumber = parseInt(query["perPage"]);
            }
        },
        getIdsAsQueryString(arrayObjects, key = "id") {
            return "id=" + arrayObjects
                .filter(function (item) {
                    return item[key];
                })
                .map(function (item) {
                    return item[key];
                })
                .join("&id=");
        },
        getPayIconByPaymentProcessorId(paymentProcessorId) {

            switch (paymentProcessorId) {
                case 2: //pocketmags online
                    return "web";
                case 1:
                case 12:
                case 16:
                case 56:
                    return "apple";
                case 4:
                case 6:
                case 35:
                case 57:
                    return "android-debug-bridge";
                case 7:
                case 9:
                case 51:
                    return "amazon";
                case 18:
                    return "cellphone";
                case 28:
                    return "access-point-network-off";
                case 27:
                    return "jellyfish";
                case 13:
                    return "facebook";
                case 3:
                case 25:
                case 26:
                case 34:
                case 37:
                    return "headset";
                default:
                    return null;
            }
        },

        getRgbFromHex(hex) {
          var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ? `${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)}` : "";
        },
        componentToHex(c) {
            var hex = c.toString(16);
            return hex.length == 1 ? "0" + hex : hex;
        },
        getHexFromRgb(r,g,b) {
            return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
        },
        getHexFromRgbString(val) {
            var split = val.split(",");
            return this.getHexFromRgb(parseInt(split[0]), parseInt(split[1]), parseInt(split[2]))
        },
        textFormatting(style) {
            switch (style) {
                case 'bold':
                    document.execCommand('bold', false, ''); return false
                case 'italic':
                    document.execCommand('italic', false, ''); return false
                case 'underline':
                    document.execCommand('underline', false, ''); return false
                case 'justifyCenter':
                    document.execCommand('justifyCenter', false, ''); return false
                case 'justifyLeft':
                    document.execCommand('justifyLeft', false, ''); return false
                case 'justifyRight':
                    document.execCommand('justifyRight', false, ''); return false
                default:
            }
        },
    }
};