<template>
    <div class="modal is-active">
        <div class="modal-background" v-on:click="closeDialog(false)"></div>

        <div class="modal-card" style="width: 800px;max-width: 80%">
            <header class="modal-card-head">
                <p class="modal-card-title">Contact Us</p>
                <button class="delete" aria-label="close" v-on:click="closeDialog(false)"></button>
            </header>
            <section class="modal-card-body site-form field-body-grow-3">

                <div class="field is-horizontal">
                    <div class="field-label">
                        <label class="label">Type</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <div class="control">
                                <div class="select" v-bind:class="{ 'is-danger': HasValidationError('Type') }">
                                    <select v-model="typeSelected">
                                        <option :value="null" disabled="disabled">Please Select...</option>
                                        <option v-for="t in typeOptions" :value="t" :key="t.name">{{t.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <p v-if="GetValidationError('Type')" class="help is-danger">{{ GetValidationError('Type') }} </p>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal">
                    <div class="field-label">
                        <label class="label">To</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <div class="control">
                                <input class="input" type="text"
                                       disabled="disabled"
                                       :value="toAddress">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal">
                    <div class="field-label">
                        <label class="label">From (User)</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <div class="control">
                                <input class="input" type="text"
                                       disabled="disabled"
                                       v-model="form.username"
                                       v-bind:class="{ 'is-danger': HasValidationError('Username') }">
                            </div>
                            <p v-if="GetValidationError('Comment')" class="help is-danger">{{ GetValidationError('Username') }} </p>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal">
                    <div class="field-label">
                        <label class="label">From (Email Address)</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <div class="control is-flex">
                                <input class="input" type="text"
                                       ref="email"
                                       :disabled="!form.notOwnEmail"
                                       v-model="form.email"
                                       v-bind:class="{ 'is-danger': HasValidationError('Email') }">
                                <a class="mt-2 ml-2" v-if="!form.notOwnEmail" @click.stop="NotYourEmail()">Not your email address?</a>
                            </div>
                            <p v-if="GetValidationError('Comment')" class="help is-danger">{{ GetValidationError('Email') }} </p>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal">
                    <div class="field-label">
                        <label class="label">Message</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <div class="control">
                                <textarea class="textarea"
                                          v-model="form.comment"
                                          style="min-height:120px;"
                                          v-bind:class="{ 'is-danger': HasValidationError('Comment') }"></textarea>
                            </div>
                            <p v-if="GetValidationError('Comment')" class="help is-danger">{{ GetValidationError('Comment') }} </p>
                        </div>
                    </div>
                </div>

            </section>
            <footer class="modal-card-foot is-justify-content-flex-end">
                <button class="button is-primary " @click="send" :disabled="typeSelected === null">Send Feedback</button>
            </footer>

        </div>

    </div>
</template>
<script>
    import { mapState } from 'vuex'
    import baseMixin from '@/mixins/mixin'
    import Axios from 'axios'
    export default {
        name: 'FeedbackModal',
        components: {},
        mixins: [baseMixin],
        props: {
        },
        data() {
            return {
                isLoading: false,
                modelState: null,
                typeSelected: null,
                form: {
                    comment: '',
                    username: '',
                    email: '',
                    notOwnEmail: false
                },

                typeOptions: [
                    {
                        id: "BackOfficeSupport",
                        name: "Back Office Support",
                        to: "support@jellyfishconnect.com"
                    },
                    {
                        id: "AccountManagement",
                        name: "Account Management",
                        to: "clientsupport@jellyfishconnect.com"
                    },
                    {
                        id: "EndUserSupport",
                        name: "End-user (e.g. subscriber) Support",
                        to: "help@pocketmags.com"
                    },
                    {
                        id: "Finance",
                        name: "Finance",
                        to: "accounts@jellyfishconnect.com"
                    },
                    {
                        id: "SiteFeedback",
                        name: "Site Feedback",
                        to: "feedback@magazinecloner.com"
                    }
                ]
            }
        },
        computed: {
            ...mapState([
                'clientTitlesBase'
            ]),
            toAddress() {
                return this.typeSelected !== null ? this.typeSelected.to : "-";
            }
        },
        created() {
            this.form.username = this.$store.state.user.userName;
            this.form.email = this.$store.state.user.emailAddress;
        },
        methods: {
            send() {
                var that = this
                this.isLoading = true
                this.modelState = null;

                var dataForm = {
                    userId: this.$store.state.user.userId,
                    clientId: this.$store.state.user.clientId,
                    type: this.typeSelected.id,
                    to: this.toAddress,
                    email: this.form.email,
                    userName: this.form.username,
                    notOwnEmail: this.form.notOwnEmail,
                    comment: this.form.comment,
                    feedbackPage: this.$route.meta.title,
                };

                Axios.post(`contact-us`, dataForm)
                    .then(function () {
                        that.isLoading = false
                        that.$buefy.toast.open({
                            message: that.typeSelected.id === "SiteFeedback" ? `Thank you, your feedback has been submitted`
                                : `Thank you for contacting us, we look to respond to your comments within 48 hours`,
                            duration: 4000
                        })
                        that.closeDialog(true);
                    })
                    .catch((error) => {
                        that.isLoading = false
                        that.modelState = error.response.data;
                    })
            },
            closeDialog(submited) {
                this.$emit(submited ? 'submit' : 'close');
            },
            NotYourEmail() {
                this.form.notOwnEmail = true
                this.form.email = "";
                this.$nextTick(() => this.$refs.email.focus());
                

            }
        }
    }
</script>