<template>
    <aside class="menu pt-2">
        <template v-for="(menuGroup) in NoSubMenu">
            <router-link :to="menuGroup.to" v-bind:key="menuGroup.label">{{menuGroup.label}}</router-link>
        </template>
        <template v-for="(menuGroup) in HasSubMenu">
            <p class="menu-label mb-1" v-bind:key="menuGroup.label + 'para'">
                {{menuGroup.label}}
            </p>
            <ul class="menu-list" v-bind:key="menuGroup.label + 'list'">
                <li v-for="i in HasSubMenuLabel(menuGroup.menu)" v-bind:key="i.label">
                    <template v-if="itemVisible(i.isVisible)">
                        <a v-if="i.help" v-on:click="OpenHelpAndSupport">
                            {{ i.label }}
                        </a>
                        <a v-else-if="i.href" :href="i.href" target="_blank" @click="activate(i)" :class="{ 'is-active' : isActiveLeftNavItem(i) }">
                            {{ i.label }}
                            <span class="legacy" title="Links back to the old site currently" v-if="i.legacy">
                                <span class="icon">
                                    <i class="mdi mdi-open-in-new"></i>
                                </span>
                            </span> 
                        </a>
                        <router-link v-else-if="i.to" :to="i.to" @click.native="activate(i)" :class="{ 'is-active router-link-active' : isActiveLeftNavItem(i)  }">
                            {{ i.label }}
                            <span class="legacy" title="Links back to the old site currently" v-if="i.legacy">
                                <span class="icon">
                                    <i class="mdi mdi-open-in-new"></i>
                                </span>
                            </span>
                        </router-link>
                    </template>
                </li>
            </ul>
        </template>
    </aside>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'SideNavigationMenu',
        props: {
            menu: {
                type: Array,
                required: true
            }
        },

        data() {
            return {
                active_el: 'Magazine Dashboard'
            }
        },
        created() {
            this.active_el = this.$route.name;

            this.$root.$on('setActiveLinkInSideBar', (link) => {
                this.active_el = link
            })
        },
        computed: {
            ...mapState([
                'user'
            ]),
            NoSubMenu() {
                if (this.menu !== undefined) {
                    return this.menu.filter(function (item) {
                        return item.menu === undefined && this.itemVisible(item.isVisible)
                    })
                }
                return []
            },
            HasSubMenu() {
                var that = this

                if (this.menu !== undefined) {
                    return this.menu.filter(function (item) {
                        return that.itemVisible(item.isVisible)
                    })
                }
                return []
            }
        },
        methods: {
            itemVisible(isVisible) {
                if (isVisible === undefined || isVisible === null)
                    return true;
                return isVisible;
            },
            activate(el) {
                this.active_el = el.to.name;
            },
            isActiveLeftNavItem(link) {
                return this.active_el == link.to.name || this.active_el +"Base" == link.to.name;
            },
            HasSubMenuLabel(menu) {
                return menu.filter(function (item) {
                    return item.label.length > 0
                })
            },
            OpenHelpAndSupport() {
                this.$store.commit('setSupportDialog', true);
            }
        },
        watch: {
            $route(to) {
                this.active_el = to.name;
            }
        }
    }
</script>
