import Vue from 'vue';
import Buefy from 'buefy'
import '@/scss/setup.scss'

import App from './App.vue';
//import 'buefy/dist/buefy.css'
import router from './router'
import store from './store'
import Axios from 'axios'
import * as dayjs from 'dayjs'
import AuthService from '@/services/auth-service'
import Clipboard from 'v-clipboard'
import ReadMore from 'vue-read-more';

var customParseFormat = require('dayjs/plugin/customParseFormat')
var dayjsUtc = require('dayjs/plugin/utc')
var dayjsTimezone = require('dayjs/plugin/timezone')
dayjs.extend(customParseFormat)
dayjs.extend(dayjsUtc)
dayjs.extend(dayjsTimezone)

Axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT
Vue.config.devtools = true
Vue.config.productionTip = true;
Vue.prototype.$dayjs = dayjs;

Vue.directive('visible', function (el, binding) {
    el.style.visibility = binding.value ? 'visible' : 'hidden';
});

// Request interceptor for API calls
Axios.interceptors.request.use(
    async config => {
        var user = store.state.user;
        var token = user !== null && user.accessToken !== undefined ? user.accessToken : "";
        config.headers = {
            'Authorization': `Bearer ${token}`
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });

// Response interceptor for API calls
Axios.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config;
    
    if (error.response) {
        // Request made and server responded
        var isAuthorisationError = error.response.status === 403 || error.response.status === 401;

        if (isAuthorisationError && !originalRequest._retry) {
            originalRequest._retry = true;

            await AuthService.refreshAccessToken();

            var user = store.state.user;
            var token = user !== null && user.accessToken !== undefined ? user.accessToken : "";
            //const access_token = await refreshAccessToken();
            Axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

            if (originalRequest.data && typeof originalRequest.data === 'string')
                originalRequest.data = JSON.parse(originalRequest.data); //otherwise the content type is set as text not json on second attempt

            return Axios(originalRequest);
        }

    } else if (error.request) {
        // The request was made but no response was received
    } else {
        // Something happened in setting up the request that triggered an Error
    }

    return Promise.reject(error);
});

const defaultDocumentTitle = 'Portal'
router.afterEach(to => {


    if (to.meta.title) {
        document.title = `${to.meta.title} | ${defaultDocumentTitle}`
    } else {
        document.title = defaultDocumentTitle
    }
})

Vue.use(ReadMore)
Vue.use(Clipboard)
Vue.use(Buefy)
new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app');


Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}
