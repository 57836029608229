<template>
    <div id="app" v-bind:class="{ 'is-scrolled': mainWindowScrolled, 'has-description': hasBreadcrumbDescription }">
        <!--Description {{hasBreadcrumbDescription}},
        scrolled {{mainWindowScrolled}},-->
        <router-view />

    </div>
</template>

<script>
    import { mapState } from 'vuex'
    export default {
        name: 'app',
        created() {
            window.addEventListener('beforeunload', this.beforeWindowUnload)
        },
        computed: {
            ...mapState([
                'hasUnsavedChanges', 'mainWindowScrolled', 'hasBreadcrumbDescription'
            ])
        },
        methods: {
            confirmStayInDirtyForm() {
                return this.hasUnsavedChanges && !window.confirm('Do you really want to leave? you have unsaved changes!');
            },
            beforeWindowUnload(e) {
                if (this.confirmStayInDirtyForm()) {
                    // Cancel the event
                    e.preventDefault()
                    // Chrome requires returnValue to be set
                    e.returnValue = ''
                }
            },
        },

    };
</script>
