<template>
    <div>
        <nav id="navbar-main" class="navbar is-fixed-top is-primary">
            <div class="navbar-brand">
                <a class="navbar-item is-hidden-desktop" @click.prevent="menuToggleMobile">
                    <b-icon :icon="menuToggleMobileIcon" />
                </a>

                <router-link :to="{ name: 'Dashboard' }" class="navbar-item">
                    <img src="/MagCloner-Logo-White-Small.png" style="width:auto;" />
                </router-link>
            </div>


            <div class="navbar-menu fadeIn animated faster" :class="{'is-active':isMenuNavBarActive}">
                <div class="navbar-end">

                    <b-navbar-item @click="OpenHelpAndSupport()" style="border-right: 1px solid rgb(219, 219, 219, 77%)">
                        <b-icon icon="help-circle-outline" size="is-small"></b-icon>
                        <span style="padding-left:3px;">Help &amp; Support</span>
                    </b-navbar-item>

                    <nav-bar-menu class="has-divider">
                        <div class="is-user-name">
                            <span>{{ user.userName }}</span>&nbsp;({{ user.role }})
                        </div>
                        <div slot="dropdown" class="navbar-dropdown">
                            <router-link :to="{ name: 'MyProfile' }" class="navbar-item" exact-active-class="is-active">
                                <b-icon icon="account" custom-size="default"></b-icon>
                                <span> Account Details</span>
                            </router-link>
                            <router-link v-if="CanAccessClientAdmin" :to="{ name: 'UserManagement' }" class="navbar-item">
                                <b-icon icon="cog" custom-size="default" />
                                <span>User Management</span>
                            </router-link>
                            <router-link v-if="CanAccessClientAdmin" :to="{ name: 'ContactList' }" class="navbar-item">
                                <b-icon icon="contacts" custom-size="default" />
                                <span>Contact List</span>
                            </router-link>
                            <a v-if="CanAccessPatner" class="navbar-item" @click="showClientLoginModal = true">
                                <b-icon icon="account" custom-size="default"></b-icon>
                                <span>Client Login</span>
                            </a>
                            <hr class="navbar-divider">
                            <a class="navbar-item" @click="logout">
                                <b-icon icon="logout" custom-size="default"></b-icon>
                                <span>Log Out</span>
                            </a>
                        </div>
                    </nav-bar-menu>
                </div>
            </div>
        </nav>
        <client-user-login-modal v-if="showClientLoginModal" v-on:close="showClientLoginModal = false" />
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import baseMixin from '@/mixins/mixin'
    import permissionsMixin from '@/mixins/permissions'
    import NavBarMenu from '@/components/molecules/NavBarMenu'
    import Axios from 'axios'
    import ClientUserLoginModal from '@/components/organisms/ClientUserLoginModal'

    export default {
        name: 'NavBar',
        components: {
            NavBarMenu,
            ClientUserLoginModal,
        },
        mixins: [baseMixin, permissionsMixin],
        data() {
            return {
                isLoading: false,
                isMenuNavBarActive: false,
                showClientLoginModal: false
            }
        },
        computed: {
            menuNavBarToggleIcon() {
                return (this.isMenuNavBarActive) ? 'close' : 'dots-vertical'
            },
            menuToggleMobileIcon() {
                return this.isAsideMobileExpanded ? 'backburger' : 'forwardburger'
            },
            ...mapState([
                'user',
                'isAsideMobileExpanded',
            ])
        },
        mounted() {
        },
        methods: {
            menuToggleMobile() {
                this.$store.commit('asideMobileStateToggle', !this.isAsideMobileExpanded)
            },
            menuNavBarToggle() {
                this.isMenuNavBarActive = (!this.isMenuNavBarActive)
            },
            logout() {
                var that = this;
                this.isLoading = true
                Axios.post('logout?refreshToken=' + that.user.refreshToken).then(() => {
                    that.navBarDisplay(false)
                    window.localStorage.clear()
                    that.$store.commit('user', null) //backup if the session storage doesn't clear
                    that.isLoading = false
                    that.$router.push({ name: 'LoginView' })

                }).catch((error) => {
                    that.isLoading = false
                    that.$buefy.snackbar.open(error);
                })
                
            },
            OpenHelpAndSupport() {
                this.$store.commit('setSupportDialog', true);
            }
        }
    }
</script>

<style scoped>
    .navbar.nav-background {
        background-color: #0092A8;
        color: white !important;
    }
</style>
