import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState(['user']),

        CanAccessSiteAdmin() {
            return this.isUserAllowedAccess(["admin"]);
        },
        CanAccessPatner() {
            return this.isUserAllowedAccess(["admin", "partner"]);
        },
        CanAccessClientAdmin() {
            return this.isUserAllowedAccess(["admin", "partner", "client"]);
        },
        CanAccessPromotions() {
            return this.isUserAllowedAccess(["admin", "partner", "client", "restrictedtitles", "publisher", "production"]);
        },
        CanAccessMagazines() {
            return this.isUserAllowedAccess(["admin", "partner", "client", "restrictedtitles", "publisher", "production"]);
        },
        CanAccessFinanceReports() {
            return this.isUserAllowedAccess(["admin", "partner", "client", "restrictedtitles", "publisher", "financial", "reporting"]);
        },
        CanAccessAnalyticReports() {
            return this.isUserAllowedAccess(["admin", "partner", "client", "restrictedtitles", "publisher", "reporting"]);
        },
        CanAccessFinancial() {
            return this.isUserAllowedAccess(["admin", "partner", "client", "restrictedtitles", "publisher", "financial"]);
        },
        CanAccessDataProtectionOfficer() {
            return this.isUserAllowedAccess(["dpopublisher"]);
        },
        CanAccessCustomerAdmin() {
            return this.isUserAllowedAccess(["customerservice"]);
        },
    },
    methods: {
        isUserAllowedAccess(lsAllowedRoles) {
            var role = this.user !== null ? this.user.role.toLowerCase() : "";
            return lsAllowedRoles.some(function (r) { return r.toLowerCase() == role });
        }
    }
};